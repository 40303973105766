body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
}

/*all text */
* label, p, h3, h4, h5, h6, a, span, button {
  color: #818181;
}

* h1, h2 {
  color: #6b6b6b;
}

.configurator {
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 20px;
  /*padding-top: 10px;*/
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: white;
  transition: height 0.5s ease-out;
  max-height: 90vh;
  min-height: 90vh;
  overflow: auto;
}


.sidebar {
  /*width: 520px;*/
  /*height: 500px;*/
}

.sidebar > .sidebar-content {
  flex-grow: 1; /* Allow the sidebar content to take up the remaining space */
  width: 100%;;
}

.edge-card {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  margin: 5px;
  transition: transform 0.05s ease;
}

.edge-card:hover {
  transform: scale(1.01);
  transition: transform 0.1s ease;
}


.forgot-password {
  color: #818181;
  text-decoration: none;
  font-size: 12px;
  text-align: end;
}

.forgot-password * button {
  color: #818181;
  text-decoration: none;
  margin: 10px;
}

.kein-konto {
  color: #818181;
  text-decoration: none;
  font-size: 12px;
  margin-bottom: 30px;
}

.kein-konto > a {
  color: #818181;
  text-decoration: none;
  font-size: 12px;

  font-weight: bold
}

.Auth-form {
  margin-top: 5vh;
  width: 520px;
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 30px;
  padding-top: 60px;
  border-radius: 50px;
  background-color: white;
}

* .Auth-form-content > div > p {
  color: #5e5e5e;
  font-size: 15px;
  text-align: start;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.recharts-cartesian-axis-tick {
  font-size: 0.8rem;
}